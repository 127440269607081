@import 'src/styles/imports';

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  .title {
    position: relative;
    margin: 80px 0 60px;
    font-size: 3.2rem;
  }

  @include media(S) {
    .title {
      text-align: center;
    }
  }
}
