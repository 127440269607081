@import 'src/styles/imports';

.guideCategories {
  align-self: flex-start;
  width: 100%;

  ul {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 30px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:not(:nth-last-of-type(-n + 3)) {
        border-bottom: 1px solid var(--color-border);
      }
    }
  }

  &.mini {
    ul {
      grid-template-columns: repeat(1, 1fr);

      li {
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-border);
        }
      }
    }
  }
}
