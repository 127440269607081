@import 'src/styles/imports';

.text {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  line-height: 1.5;

  a:link {
    .left {
      display: flex;
      flex-direction: row-reverse;
    }

    .right {
      display: flex;
    }
  }

  a:visited {
    text-decoration: none;
    color: var(--card-cta-bg-color);
  }

  a:link:hover,
  a:link:focus-visible {
    cursor: pointer;
    text-decoration: underline;
  }

  a:link:active {
    transition: all ease-in-out 0.3s;
    transform: translateY(2px);
  }
}

@include media(S) {
  .text {
    justify-content: center;
    text-align: center;
  }
}

.visuallyHidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
