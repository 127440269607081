@import 'src/styles/imports';

.guideLink {
  display: block;
  height: 100%;

  &:focus-visible {
    border-radius: 16px;
    outline: 2px solid var(--card-input-focused-border-color);
    outline-offset: 2px;
    box-shadow: $shadow-lg;
    transform: translateY(-10px);
  }
}

.guideCard {
  transition: all ease-in-out 0.3s;
  height: 100%;
  padding: 20px;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  border-bottom: 2px solid var(--card-border-color);
  border-radius: 16px;
  color: var(--global-text-color);
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .descriptionText {
    color: var(--card-text-secondary-color);
    line-height: 150%;
  }

  .visuallyHidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  h3 {
    letter-spacing: -0.2px;
    line-height: 120%;
    color: var(--global-text-color);
  }

  &:hover {
    cursor: pointer;
    box-shadow: $shadow-lg;
    transform: translateY(-10px);
  }

  &:active {
    transform: translateY(-2px);
  }
}
