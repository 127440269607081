@import 'src/styles/imports';

.apps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:not(:nth-last-of-type(-n + 3)) {
        border-bottom: 1px solid var(--color-border);
      }
    }
  }
}
