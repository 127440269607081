@import 'src/styles/imports';

.headerContainer {
  background-color: $color-blue-900;
  color: var(--card-cta-text-color);
  padding: 60px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
  }

  .illustration {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

@include media(S) {
  .headerContainer {
    padding: 20px;

    .header {
      width: 100%;
      padding-bottom: 200px;
    }

    .illustration {
      top: 230px;
      z-index: 0;
    }
  }
}
