@import 'src/styles/imports';

.appLink {
  display: block;
  height: 100%;

  &:focus-visible {
    border-radius: 16px;
    outline: 2px solid var(--card-input-focused-border-color);
    outline-offset: 2px;
    box-shadow: $shadow-lg;
    transform: translateY(-10px);
  }
}

.appCard {
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 16px;

  .illustrationBox {
    background-color: $color-blue-900;
    border-radius: 16px 16px 0 0;
    min-height: 200px;
    position: relative;
    overflow: hidden;

    .illustration {
      position: absolute;
      right: 0;
      overflow: hidden;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: $shadow-lg;
    transform: translateY(-10px);
  }

  &:active {
    transform: translateY(-2px);
  }

  .appButton {
    background-color: var(--card-bg-color);
    border: solid 1px var(--card-border-color);
    color: var(--global-text-color);
    padding: 20px;
    border-radius: 0 0 16px 16px;
    height: 100%;

    .title {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .visuallyHidden {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
}
