@import 'src/styles/imports';

.backButton {
  display: flex;
  gap: 4px;
  padding: 0;

  :hover,
  :focus-visible {
    cursor: pointer;
    text-decoration: underline;
  }

  :active {
    transition: all ease-in-out 0.3s;
    transform: translateY(2px);
  }
}
