@import 'src/styles/imports';

.pageContainer {
  width: 100%;
  padding: 40px 40px 80px;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: scroll;

  &.center {
    align-items: center;
  }

  &.default {
    align-items: flex-start;
  }

  .contentContainer {
    width: 100%;
    max-width: 1180px;
    display: flex;
    gap: 50px;
    flex-direction: column;

    &:focus-visible {
      outline: 2px solid var(--color-cta);
    }
  }
}

@include media(S) {
  .pageContainer {
    padding: 40px 20px 80px;
  }
}
