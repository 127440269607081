@import 'src/styles/imports';

.guide {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;

  .content {
    p,
    h3 {
      margin: 10px 0;
    }

    img {
      display: block;
      width: 75%;
      border-radius: 16px;
      box-shadow: $shadow-md;
      margin: 10px 4px;
    }

    ol {
      line-height: 2.4rem;
      margin: 0;
      padding: 0;
      counter-reset: guide-counter;
      list-style: none;

      li {
        position: relative;
        padding: 10px 0;
        counter-increment: guide-counter;
        text-indent: 34px;

        &::before {
          content: counter(guide-counter);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          text-indent: 0;
          left: 0;
          top: 10px;
          width: 24px;
          height: 24px;
          font-weight: bold;
          background-color: $color-slate-100;
          border-radius: 50%;
        }
      }
    }

    ul {
      line-height: 1.6em;
      margin: 0;
    }

    .loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include media(S) {
      img {
        width: 98%;
        border-radius: 8px;
      }
    }
  }

  .feedback {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .divider {
    height: 1px;
    background-color: var(--card-border-color);
  }
}
