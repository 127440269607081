@import 'src/styles/imports';

.guideContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  animation: fadeIn ease-in-out 0.5s forwards 1s;

  ul {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 20px;
    display: grid;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include media(S) {
    ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
