@import 'src/styles/imports';

.userGuideContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .extLink {
    align-self: center;
    color: var(--card-text-secondary-color);
  }
}
